// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HTML: <div class="loader"></div> */
.mini-loader {
  width: 60px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #c9c9c9 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: 33.3333333333% 100%;
  animation: l7 1s infinite linear;
}

@keyframes l7 {
  33% {
    background-size: 33.3333333333% 0%, 33.3333333333% 100%, 33.3333333333% 100%;
  }
  50% {
    background-size: 33.3333333333% 100%, 33.3333333333% 0%, 33.3333333333% 100%;
  }
  66% {
    background-size: 33.3333333333% 100%, 33.3333333333% 100%, 33.3333333333% 0%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Loader/Loader.scss"],"names":[],"mappings":"AAAA,qCAAA;AACA;EACI,WAAA;EACA,eAAA;EACA,wEAAA;EACA,mEAAA;EACA,oCAAA;EACA,gCAAA;AACJ;;AACE;EACE;IACE,4EAAA;EAEJ;EAAE;IACE,4EAAA;EAEJ;EAAE;IACE,4EAAA;EAEJ;AACF","sourcesContent":["/* HTML: <div class=\"loader\"></div> */\n.mini-loader {\n    width: 60px;\n    aspect-ratio: 4;\n    --_g: no-repeat radial-gradient(circle closest-side, #c9c9c9 90%, #0000);\n    background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;\n    background-size: calc(100% / 3) 100%;\n    animation: l7 1s infinite linear;\n  }\n  @keyframes l7 {\n    33% {\n      background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;\n    }\n    50% {\n      background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;\n    }\n    66% {\n      background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
